import GLightbox from 'glightbox'

const galleries = Array.prototype.slice.call(document.querySelectorAll('[data-gallery]'));

if(galleries.length) {

    galleries.forEach(gallery => {

        const selector = '.gallery-images-' + gallery.dataset.gallery;

        GLightbox({
            selector: selector,
            // onOpen: () => {
            //
            //     const container = document.querySelector(".gcontainer");
            //     const gclose = document.querySelector(".gclose");
            //     const gprev = document.querySelector(".gprev");
            //     const gnext = document.querySelector(".gnext");
            //     const close_top = gclose.offsetTop + 5;
            //     const close_left = gclose.offsetLeft - 30;
            //     const rotate_icon = document.createElement("A");
            //     gclose.ariaLabel = 'Zavrieť';
            //     gprev.ariaLabel = 'Predošla';
            //     gnext.ariaLabel = 'Ďalšia';
            //     rotate_icon.setAttribute("style", "width:22px;height:22px;display:block;opacity:0.9;position:absolute;left:"+close_left+"px;top:"+close_top+"px;z-index:999999;cursor:pointer;background-image:url(/assets/icons/bold-outline-icons/Interface/Interface_Interface-white-03.svg);background-size:100%");
            //
            //     rotate_icon.addEventListener("click", function(ev) {
            //         ev.preventDefault();
            //         ev.stopPropagation();
            //         var img_el = document.querySelector(".gslide.loaded.current img");
            //         var deg = img_el.getAttribute('data-deg') || 360;
            //         deg -= 90;
            //         if(deg < 0) deg = 270;
            //         img_el.setAttribute("data-deg", deg);
            //         img_el.setAttribute("style", "transform:rotate("+deg+"deg)");
            //     });
            //
            //     container.appendChild(rotate_icon);
            //
            // }
        });

    });

}
